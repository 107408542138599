.fc-event-title {
    text-transform: capitalize;
}

.fc-list-event-title {
    text-transform: capitalize;
}

.fc-button-primary {
    background-color: rgb(3, 84, 24) !important;
    text-transform: capitalize !important;
}

a.fc-daygrid-event {
    cursor: pointer;
}

a.fc-weekview-event {
    cursor: pointer;
}

.fc-list-event a {
    cursor: pointer;
}