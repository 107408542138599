.text-black-alt {
  color: #191919;
}

.border-black-alt {
  border-color: #191919;
}

.top-nav-link.active {
  color: white;
}